
import { defineComponent, PropType, reactive, toRefs, ref, h, onMounted, getCurrentInstance, ComponentInternalInstance, createVNode, } from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import { QuestionControllerPage, find, QuestionController_del, updateStatus_put,QuestionController_importByExcel } from "@/api/api"
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { questionManagement,questionManagementDownloadSelectData } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "试题描述 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "问题类别",
    dataIndex: "categoryId",
    slots: { customRender: "categoryId" },
  },

  {
    title: "问题类型",
    customRender: (text: TransformCellTextProps) => {
      return (text.text == 1 ? "单选题" : (text.text == 2 ? "多选题" : "判断题"))
    },
    dataIndex: "type",
  },
  {
    title: "问题状态",
    dataIndex: "status",
    customRender: (text: TransformCellTextProps) => (text.text == 0 ? "未发布" : "已发布"),
  },
  {
    title: "默认分值",
    dataIndex: "score",
  },
  {
    title: "难易程度",
    dataIndex: "difficultyLevel",
    customRender: (text: TransformCellTextProps) => (text.text == 0 ? "简单" : (text.text == 1 ? "中等" : "困难")),
  },
  {
    title: "创建人",
    dataIndex: "createdBy",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    sorter: true,
  },
  {
    title: "更新人",
    dataIndex: "updatedBy",
  },
  {
    title: "更新时间",
    dataIndex: "updatedTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    formSearch,
    UploadOutlined,
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    const options = reactive([] as any[])
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 8 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        //试题描述：
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "试题描述：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "title", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        //试题类别：
        {
          rowProps: {},
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "试题类别：",
          },
          comConfig: {
            tag: "select",
            key: "categoryId",
            valueKey: "id",
            labelKey: "name",
            getDataApi: () => {
              return new Promise((reslove) => {
                find({ type: 1 }).then(res => {
                  reslove(
                    res.data.map((res: { categoryId: any; name: any; }) => {
                      options.push({
                        value: res.categoryId,
                        label: res.name,
                      })
                      return { id: res.categoryId, name: res.name }
                    })
                  );
                })
              });
            },
            props: {
              placeholder: "请输入",
            },
          },
        },
        //问题类型：
        {
          rowProps: {},
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "问题类型：",
          },
          comConfig: {
            tag: "select",
            key: "type",
            valueKey: "id",
            labelKey: "name",
            getDataApi: () => {
              return new Promise((reslove) => {
                setTimeout(() => {
                  reslove([
                    {
                      name: "单选题",
                      id: 1,
                    },
                    {
                      name: "多选题",
                      id: 2,
                    },
                    {
                      name: "判断题",
                      id: 3,
                    },
                  ]);
                }, 500);
              });
            },
            props: {
              placeholder: "请输入",
            },
          },
        },
        // 更新时间
        {
          rowProps: {},
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "创建时间",
          },
          comConfig: {
            tag: "input",
            key: "testSlots",
            props: {
              placeholder: "请输入",
            },
          },
        },
        // 难易程度：
        {
          rowProps: {},
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "难易程度：",
          },
          comConfig: {
            tag: "select",
            key: "difficultyLevel",
            valueKey: "id",
            labelKey: "name",
            getDataApi: () => {
              return new Promise((reslove) => {
                setTimeout(() => {
                  reslove([
                    {
                      name: "简单",
                      id: 0,
                    },
                    {
                      name: "中等",
                      id: 1,
                    },
                    {
                      name: "困难",
                      id: 2,
                    },
                  ]);
                }, 500);
              });
            },
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[],) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          ...formSearch.value.getQuery(),
          'startUpdateTime': updatedTime.value[0],
          'endUpdateTime': updatedTime.value[1],
          sort: params.sort,
        }
        QuestionControllerPage({ ...params_api.value }).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      questionManagement({ ...params_api.value }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        questionManagementDownloadSelectData({ ids:data.selectedRowKeys}).then(res => {
          blob(res)
        })
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      data.selectedRowKeys=[]
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑试题";
      popupWindow.value.showModal(row.questionId);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleAdd = () => {
      Title.value = "新增试题";
      popupWindow.value.showModal();
    };
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh();
    };
    const reset = () => {
      updatedTime.value = []
    };
    const delClick = (text: string, row: any) => {
      const id = reactive([] as any[])
      id.push(row.questionId)
      QuestionController_del({ id: id.join(',') }).then(res => {
        table.value.refresh();
      })
    }
    const refresh = () => {
      table.value.refresh();
      data.selectedRowKeys = []
    }
    const category = (val: any) => {
      return options.find(item => item.value === val)?.label
    }
    const handleDeleteAll = () => {
      (proxy as any).$confirm({
        title: "删除确认",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "确认删除选中试题吗？",
        okType: " ",
        onOk: () => {
          return new Promise((resolve) => {
            QuestionController_del({ id: data.selectedRowKeys.join(',') }).then(() => {
              data.selectedRowKeys = []
            })
              .finally(() => {
                refresh();
                resolve(true)
              });
          });
        },
      });
    };
    const handleUpdateStatus = (status: string) => {
      const statusName = status === '1' ? '发布' : '取消发布';
      (proxy as any).$confirm({
        title: `${statusName}确认`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: `确认${statusName}选中试题吗？`,
        onOk: () => {
          return new Promise((resolve) => {
            updateStatus_put({
              status,
              questionIds: data.selectedRowKeys
            }).then(() => {
              (proxy as any).$message.success(`试题${statusName}成功`);
            })
              .finally(() => {
                resolve(true);
                refresh();
              })
          });
        },
      });
    };
    const api = process.env.VUE_APP_API_BASE_URL;
    const visible = ref<boolean>(false);
    const importByExcel = () => {
      visible.value = true;
    }
    const Download = () => {
      window.open('http://qyapi.ruanwe.com/uploads/doc/10011/2022/1207/4a3e2ba871d643598afdea1750859c29.xlsx')
    };
    const fileList = ref<FileItem[]>([]);
    const uploadEvaluate = (file:any) => {
      const form = new FormData()
      form.append('file', file.file)
      QuestionController_importByExcel(form).then(res=>{
        visible.value = false;
        fileList.value = []
        refresh();
      })
    };
    return {
      downloadSelectApi,
      uploadEvaluate,
      params_api,
      fileList,
      Download,
      api,
      visible,
      importByExcel,
      handleDeleteAll,
      handleUpdateStatus,
      category,
      refresh,
      delClick,
      reset,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleDownload,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd,
      handleSearch,
      updatedTime,
    };
  },
});
