<template>
  <div>
    <a-modal width="800px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="cancel">
      <div style="height:600px;overflow:auto;padding:10px">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <a-form-item label="问题类型：" name="type">
            <a-radio-group name="radioGroup" v-model:value="formState.type" @change=change(formState.type)>
              <a-radio :value="1">单选题</a-radio>
              <a-radio :value="2">多选题</a-radio>
              <a-radio :value="3">判断题</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="问题类别：" name="categoryId">
            <a-select ref="select" style="width:30%" v-model:value="formState.categoryId" :options="options">
            </a-select>
          </a-form-item>
          <a-form-item label="题干内容：" name="title">
            <a-textarea v-model:value="formState.title" />
          </a-form-item>
          <a-form-item label="试题选项：">
            <div v-for="(item,index) in formState.options" :key="index" style="margin-bottom:15px">
              <a-row style="height:40px;line-height: 40px;">
                <a-col :span="1" />
                <a-col :span="3"><span style="font-size:16px">选项{{letter[index]}}：</span></a-col>
                <a-col :span="1" />
                <a-col :span="16">
                  <a-form-item :name="['options', index, 'content']"
                    :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <a-input v-model:value="formState.options[index].content" placeholder="请填写" />
                  </a-form-item>
                </a-col>
                <a-col :span="2" />
                <a-col :span="1">
                  <myIcon v-if="index>3" name="delete" style="cursor:pointer;" @click="del(index,letter[index])" />
                </a-col>
              </a-row>
            </div>
            <span v-if="!correctAnswerShow">（最多支持6个选项）</span><br>
            <a-button v-if="!correctAnswerShow" type="primary" style="background:#fff;color:black"
              @click="add(formState.options.length)">
              <myIcon name="plus" />添加新选项
            </a-button>
          </a-form-item>
          <!-- <a-form-item label="试题解析：" name="analysis"> -->
          <a-form-item label="试题解析：">
            <a-textarea v-model:value="formState.analysis" placeholder="略" />
          </a-form-item>
          <a-form-item label="试题分值：" name="score">
            <a-input-number id="inputNumber" v-model:value="formState.score" :min="0" />
          </a-form-item>
          <!-- <a-form-item label="试题难度：" name="difficultyLevel"> -->
          <a-form-item label="试题难度：">
            <a-select ref="select" style="width:30%" v-model:value="formState.difficultyLevel"
              :options="difficultyLeveloptions">
            </a-select>
          </a-form-item>
          <div v-if="correctAnswerShow">
            <a-form-item label="正确答案：" name="answer">
              <a-radio-group name="radioGroup" v-model:value="formState.answer">
                <a-radio value="A" class="radio">选项A</a-radio>
                <a-radio value="B" class="radio">选项B</a-radio>
                <a-radio v-if="formState.type!='3'" value="C" class="radio">选项C</a-radio>
                <a-radio v-if="formState.type!='3'" value="D" class="radio">选项D</a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
          <div v-if="!correctAnswerShow">
            <a-form-item label="正确答案：" name="answer">
              <a-checkbox-group v-model:value="formState.answer" :options="plainOptions" />
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
  import myIcon from "@/components/icon";
  import { defineComponent } from "vue";
  import { QuestionController_post, find, QuestionController_id, QuestionController_put } from '@/api/api'
  export default defineComponent({
    name: "index",
    props: {
      Title: {
        type: String,
        required: false,
      },
      FormData: {
        type: String,
        required: false,
      },
    },
    data() {
      const validAnswer = async (rule, value, callback) => {
        if (value && value.length > 0) {
          return Promise.resolve();
        } else {
          return Promise.reject('不能为空');
        }
      }
      return {
        plainOptions: [
          'A', 'B', 'C', 'D'
        ],
        correctAnswerShow: true,
        letter: ['A', 'B', 'C', 'D', 'E', 'F'],
        visible: false,
        confirmLoading: false,
        formState: {
          options: [{ content: '' }, { content: '' }, { content: '' }, { content: '' }],
          type: '',
          categoryId: '',
          difficultyLevel: '',
          title: '',
          analysis: '',
          score: '',
          answer: '',
        },
        rules: {
          type: [{
            required: true, message: '不能为空', trigger: 'blur', transform: (value) => {
              if (value) {
                return value.toString()
              }
              return value
            }
          },],
          title: [{ required: true, message: '不能为空', trigger: 'blur' }],
          categoryId: [{ required: true, message: '不能为空', trigger: 'blur' }],
          difficultyLevel: [{
            required: true, message: '不能为空', transform: (value) => {
              if (value) {
                return value.toString()
              }
              return value
            }
          },],
          answer: [{ validator: validAnswer }],
          analysis: [{ required: true, message: '不能为空', trigger: 'blur' }],
          score: [{
            required: true, message: '不能为空', trigger: 'blur', transform: (value) => {
              if (value) {
                return value.toString()
              }
              return value
            }
          },],
        },
        options: [],
        difficultyLeveloptions: [
          {
            value: '0',
            label: "简单",
          },
          {
            value: '1',
            label: "中等",
          },
          {
            value: '2',
            label: "困难",
          },
        ],
        editor: false
      };
    },
    components: {
      myIcon
    },
    created() {
    },
    methods: {
      change(val) {
        if (val == '1') {
          this.correctAnswerShow = true
          this.formState.answer = []
          this.formState.options = [{ content: '' }, { content: '' }, { content: '' }, { content: '' }]
        }
        if (val == '2') {
          this.correctAnswerShow = false
          this.formState.answer = ''
          this.formState.options = [{ content: '' }, { content: '' }, { content: '' }, { content: '' }]
          this.plainOptions = ['A', 'B', 'C', 'D']
        }
        if (val == '3') {
          this.correctAnswerShow = true
          this.formState.answer = []
          this.formState.options = [{ content: '正确' }, { content: '错误' }]
        }
      },
      add(index) {
        if (this.formState.options.length <= 5) {
          this.formState.options.push({ content: '' })
          this.plainOptions.push(this.letter[index])
        }
      },
      del(index, letter) {
        this.plainOptions.pop()
        this.formState.options.splice(index, 1)
      },
      cancel() {
        this.$refs.formRef.resetFields()
        console.log('清空');
        this.formState = {
          type: '',
          categoryId: '',
          difficultyLevel: '',
          title: '',
          analysis: '',
          score: '',
          answer: '',
          status: '1'
        }
        this.editor = false
        this.formState.options = [{ content: '' }, { content: '' }, { content: '' }, { content: '' }]
        this.plainOptions = ['A', 'B', 'C', 'D']
        this.visible = false;
      },
      handleOk() {
        this.$refs.formRef.validate().then(() => {
          if (this.formState.type == 2) {
            this.formState.answer = this.formState.answer.join('')
          }
          this.formState.options = this.formState.options.map((res, index) => ({ ...res, label: this.letter[index] }))
          this.confirmLoading = true
          if (!this.editor) {
            QuestionController_post(this.formState).then(res => {
              this.visible = false
              this.confirmLoading = false
              this.cancel()
              this.$parent.refresh();
            })
          } else {
            QuestionController_put(this.formState).then(res => {
              this.visible = false
              this.confirmLoading = false
              this.cancel()
              this.$parent.refresh();
            })
          }
        });
      },
      showModal(val) {
        this.visible = true;
        this.options = []
        this.editor = false
        find({ type: 1 }).then(res => {
          res.data.map(res => {
            this.options.push({
              value: res.categoryId,
              label: res.name,
            })
          })
        })
        if (val) {
          this.editor = true
          QuestionController_id(val).then(res => {
            if (res.data.type == '1') {
              this.formState = res.data
              this.formState.difficultyLevel = this.formState.difficultyLevel + ''
              this.correctAnswerShow = true
            } else if (res.data.type == '3') {
              this.formState = res.data
              this.formState.difficultyLevel = this.formState.difficultyLevel + ''
              this.correctAnswerShow = true
            } else {
              this.formState = res.data
              this.formState.difficultyLevel = this.formState.difficultyLevel + ''
              this.formState.answer = res.data.arrayAnswer
              this.correctAnswerShow = false
              this.plainOptions = []
              this.plainOptions = res.data.options.map(res => {
                return res.label
              })
            }
            if (this.formState.options.length == 0) {
              this.formState.options = [{ content: '' }, { content: '' }, { content: '' }, { content: '' }]
            }
          })
        }
      }
    },
  });
</script>
<style lang="less">
  .page-wrapper {
    padding: 20px 32px;
  }

  .radio {
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border: 1px solid #e0e0e2;
  }
</style>